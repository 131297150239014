@import '@/styles/mixins';@import '@/styles/variables';.overlayIndicatorWrapper {
  .flex-center;
  .absolute-center;

  width: 150px;
  height: 60px;
  padding: 15px;
  border-radius: 10px;
  background: @white;

  :global(.adm-spin-loading) {
    --size: 24px;
  }

  .overlayIndicatorText {
    font-size: 16px;
    margin-left: 10px;
  }
}
