@import '@/styles/mixins';@import '@/styles/variables';.nextStepButtonWrapper {
  width: 320px;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;

  :global(.adm-spin-loading) {
    margin-right: 10px;
  }
}
