@import '@/styles/mixins';@import '@/styles/variables';.cabinetSizeWrapper {
  position: absolute;
  top: 5px;
  right: 5px;

  .cabinetSizeWrapperInner {
    .flex-center;

    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: @white;

    .cabinetSize {
      color: @azure-radiance;
      font-size: 20px;
    }
  }
}
