@import '@/styles/mixins';@import '@/styles/variables';.barcodeScannerWrapper {
  height: 100%;

  .barcodeScannerWrapperInner {
    .flex-column;

    align-items: center;
    height: 100%;
    background-color: @azure-radiance;
  }
}
