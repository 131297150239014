@import '@/styles/mixins';@import '@/styles/variables';.stepItemWrapper {
  display: flex;
  width: 100%;
  font-size: 16px;

  .label {
    margin-right: 8px;
    font-weight: bold;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
}
