@import '@/styles/mixins';@import '@/styles/variables';.alertMessageWrapper {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0 13px;
  background-color: @tropical-blue;
  color: @azure-radiance;

  .alertContent {
    margin-left: 10px;
    line-height: 1.5;
    font-size: 16px;
  }
}
