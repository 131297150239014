@import '@/styles/mixins';@import '@/styles/variables';.resendButtonWrapper {
  &Inner {
    font-size: 16px;
    color: @azure-radiance;
  }

  :global(.adm-button) {
    border: none;
    padding: 0;
  }

  :global(.adm-button::before) {
    border: none;
    background-color: transparent;
  }
}
