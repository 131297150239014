@import '@/styles/mixins';@import '@/styles/variables';.operationItemWrapper {
  .flex-center-column;

  width: 49%;
  background-color: @white;

  &:hover {
    transform: translateY(-3px);
    transition: all 0.2s linear;
    background-color: @light-blue;
    cursor: pointer;
  }

  .iconWrapper {
    .flex-center;

    &Inner {
      .flex-center;

      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: @tropical-blue;
      color: @azure-radiance;
    }
  }

  .operationItemContent {
    text-transform: uppercase;
  }
}
