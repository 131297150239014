@import '@/styles/mixins';@import '@/styles/variables';.lockerHeaderTitleWrapper {
  width: 100%;
  border-bottom: 1px #eee solid;
  background-color: @white;
  font-weight: bold;

  :global(.adm-nav-bar-back-arrow) {
    font-size: 16px;
  }

  :global(.adm-nav-bar-title) {
    font-size: 16px;
  }
}
