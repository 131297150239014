@import '@/styles/mixins';@import '@/styles/variables';.phoneNoPrefixWrapper {
  &Inner {
    border: none;
    border-bottom: 0.5px solid @gray;
    padding: 0 5px;
  }

  :global(.adm-button) {
    font-size: 20px;
    line-height: 1.5;
  }

  :global(.adm-button::before) {
    border: none;
    background-color: transparent;
  }
}
