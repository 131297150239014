@import '@/styles/mixins';@import '@/styles/variables';.cabinetSizeItemWrapper {
  &Inner {
    align-items: center;
    width: 95%;
    height: 180px;
    border: 1.5px solid @azure-radiance;

    &.active {
      transform: translateY(-5px);
      transition: all 0.2s linear;
      background-color: @azure-radiance;
    }
  }

  :global(.adm-button) {
    padding: 0 10px;
  }

  :global(.adm-button::before) {
    background-color: transparent;
  }
}
