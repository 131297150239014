@import '@/styles/mixins';@import '@/styles/variables';@import url("@mdi/font/css/materialdesignicons.css");

body {
  margin: 0;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root:root {
  --adm-color-primary: @azure-radiance;
}

.adm-auto-center-content {
  word-break: normal;
  white-space: pre-line;
  text-align: center;
}

.adm-toast-mask .adm-toast-main {
  top: 90% !important;
  max-width: 80%;
}
