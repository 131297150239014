// flex
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-column {
  .flex-center;

  flex-direction: column;
}

.flex-center-vert {
  display: flex;
  align-items: center;
}

.flex-center-horiz {
  display: flex;
  justify-content: center;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
