@import '@/styles/mixins';@import '@/styles/variables';.scanningRedLineWrapper {
  .absolute-center;

  width: @qr-box-length;

  &Inner {
    animation: fadeOut 1.5s infinite;
    width: 100%;
    height: 1px;
    background-color: #ff4d4f;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
