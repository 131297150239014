@import '@/styles/mixins';@import '@/styles/variables';.displayNameInputWrapper {
  margin-top: 30px;
  padding: 0 20px;

  :global(.adm-input-element) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: center;
    font-size: 20px;
  }
}
