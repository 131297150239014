@import '@/styles/mixins';@import '@/styles/variables';.cardHeadingWrapper {
  .flex-center-vert;

  color: @azure-radiance;

  .headingTitle {
    margin-left: 10px;
    font-size: 17px;
    font-weight: bold;
    color: @black;
  }
}
