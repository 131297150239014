@import '@/styles/mixins';@import '@/styles/variables';.serviceContentWrapper {
  text-align: center;

  .serviceSlogan {
    margin: 10px 0;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .serviceDownloadContent {
    font-size: 16px;
    font-weight: bold;
    color: @gray;
  }
}
