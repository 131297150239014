@import '@/styles/mixins';@import '@/styles/variables';.sizeSymbolWrapper {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: @azure-radiance;

  &.activeSymbol {
    color: @white;
  }
}
