@import '@/styles/mixins';@import '@/styles/variables';.receiverPhoneNoWrapper {
  .flex-center-vert;

  border-bottom: 1px solid @athens-gray;
  color: @gray;

  .inputPhoneNo {
    margin-left: 10px;
  }

  :global(.adm-input-element) {
    font-size: 16px;
  }

  :global(.adm-spin-loading) {
    --size: 16px;
    margin-bottom: 8px;
  }
}
