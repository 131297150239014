@import '@/styles/mixins';@import '@/styles/variables';.unauthHomeWrapper {
  .flex-column;

  height: 100%;
  padding: 0 10px;
  background-color: @athens-gray;

  &Inner {
    .flex-column;

    flex: 1;

    :global(.adm-card) {
      margin-top: 10px;
    }
  }
}
