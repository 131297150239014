@import '@/styles/mixins';@import '@/styles/variables';.cabinetInfoItemWrapper {
  .flex-center;

  justify-content: space-between;
  color: @azure-radiance;

  &.active {
    color: @white;
  }

  .contentStyles {
    font-size: 13px;
    font-weight: bold;
  }
}
