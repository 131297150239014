@import '@/styles/mixins';@import '@/styles/variables';.cancelButton {
  margin-top: 10px;
  border: none;
  width: 100%;
  background-color: @iron;
  color: #000;
  &:hover {
    opacity: 0.7;
  }
}
