@import '@/styles/mixins';@import '@/styles/variables';.cabinetAssignWrapper {
  .flex-center-column;

  height: 100%;
  overflow-y: auto;
  background-color: @athens-gray;

  .cabinetAssignWrapperInner {
    padding: 0 10px;
    flex: 1;

    :global(.adm-card) {
      margin-top: 10px;
    }
  }
}
