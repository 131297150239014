@import '@/styles/mixins';@import '@/styles/variables';.phoneNoInputWrapper {
  margin: 10px;
  border-bottom: 0.5px solid @gray;
  width: 180px;
  padding: 0 10px;

  :global(.adm-input-element) {
    font-size: 20px;
  }
}
