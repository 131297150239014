@import '@/styles/mixins';@import '@/styles/variables';.containerPreloadWrapper {
  .flex-center-column;

  height: 100%;
  padding: 0 20px;

  .loadingWrapper {
    margin-top: 10px;
  }
}
