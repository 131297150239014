@import '@/styles/mixins';@import '@/styles/variables';.receiverNameWrapper {
  .flex-center-vert;

  margin-top: 20px;
  border-bottom: 1px solid @athens-gray;
  color: @gray;

  .inputReceiverName {
    margin-left: 10px;
    color: @athens-gray;
  }

  :global(.adm-input-element) {
    font-size: 16px;
  }
}
