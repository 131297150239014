@import '@/styles/mixins';@import '@/styles/variables';.signInWrapper {
  .flex-column;

  height: 100%;

  &Inner {
    .flex-center-column;

    flex: 1;
    padding: 0 20px;
    background-color: @white;
  }
}
