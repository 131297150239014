@import '@/styles/mixins';@import '@/styles/variables';.rentalCabinetNoWrapper {
  .flex-center;

  position: relative;
  width: 165px;
  height: 165px;
  border-radius: 10%;
  background-color: @azure-radiance;

  .cabinetNo {
    font-size: 5rem;
    font-weight: bold;
    color: @white;
  }
}
