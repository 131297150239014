@import '@/styles/mixins';@import '@/styles/variables';.displayNameWrapper {
  flex: 1;
  padding: 10px;

  .greeting {
    font-size: 14px;
  }

  .displayName {
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
  }
}
