@import '@/styles/mixins';@import '@/styles/variables';.scanningBorderWrapper {
  .absolute-center;

  @borderWidth: 6px;

  .borderPosition(@top, @right, @bottom, @left) {
    top: if(@top = 0, none, -@top);
    left: if(@left = 0, none, -@left);
    right: if(@right = 0, none, -@right);
    bottom: if(@bottom = 0, none, -@bottom);
    border-width: @top @right @bottom @left;
  }

  &Inner {
    position: relative;
    width: @qr-box-length;
    height: @qr-box-length;
    border: 1px solid #c4c4c4;

    > div {
      position: absolute;
      border-color: @azure-radiance;
      border-style: solid;
      width: 20px;
      height: 20px;
    }

    .scanningBorderTopRight {
      .borderPosition(@borderWidth, @borderWidth, 0, 0);
    }

    .scanningBorderTopLeft {
      .borderPosition(@borderWidth, 0, 0, @borderWidth);
    }

    .scanningBorderBottomRight {
      .borderPosition(0, @borderWidth, @borderWidth, 0);
    }

    .scanningBorderBottomLeft {
      .borderPosition(0, 0, @borderWidth, @borderWidth);
    }
  }
}
