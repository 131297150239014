@import '@/styles/mixins';@import '@/styles/variables';.appWrapper {
  display: flex;
  justify-content: center;
  min-height: 100dvh;
  overflow-x: hidden;

  &Inner {
    width: 100%;
    max-width: 500px;
  }
}
