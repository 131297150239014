@import '@/styles/mixins';@import '@/styles/variables';.textAvatarWrapper {
  .flex-center;

  border-radius: 100%;
  width: 45px;
  height: 45px;
  background-color: @azure-radiance;
  font-size: 18px;
  font-weight: bold;
  color: @white;
}
